import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Configuration Scenarios`}</h1>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Example:`}</strong>{` I have a group of data-literate and tech savy participants, who would get a kick out of seeing their position in the opinion landscape move around real time as they answer questions.`}
        <ul parentName="li">
          <li parentName="ul">{`Visualization on!`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Example:`}</strong>{` I'm concerned about participants feeling overwhelmed by the user interface.`}
        <ul parentName="li">
          <li parentName="ul">{`Visualization off, or on `}<a parentName="li" {...{
              "href": "/simplified-list-mode",
              "title": "simplified list mode"
            }}>{`simplified list mode`}</a>{``}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Example:`}</strong>{` I am concerned with real time results affecting the way people vote:`}
        <ul parentName="li">
          <li parentName="ul">{`Visualization off`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Example:`}</strong>{` Transparency is important to my organization`}
        <ul parentName="li">
          <li parentName="ul">{`Visualization (full or simplified/list) on: Seeing information as the results come in may feel more transparent.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Example:`}</strong>{` I have a contentious issue which needs all the help it can get in building consensus.`}
        <ul parentName="li">
          <li parentName="ul">{`Participants having access to the visualization in real time may steer them tend towards consensus building behavior. This has not been tested, but is a hypothesis of ours.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Example:`}</strong>{` We are going to send the poll out publicly, and wish to take advantage of identity systems from social media to verify users`}
        <ul parentName="li">
          <li parentName="ul">{`Social on`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Example:`}</strong>{` I'm part of a closed group with defined membership, I'm a trusted holder of data, i want everyone to see the visualizations, but I don't want people's media icons to be shown in the visualization:`}
        <ul parentName="li">
          <li parentName="ul">{`Visualizations on`}</li>
          <li parentName="ul">{`Social off`}</li>
          <li parentName="ul">{``}<a parentName="li" {...{
              "href": "/xid",
              "title": "xid"
            }}>{`xid`}</a>{` which requires polis being embedded in a webpage, collect email addresses and then send each a unique URL to each email. At time of writing, this option requires data cleaning to merge all records generated from the same URL.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Example: We have a way to share the link within our group, so we don't need any additional privacy protections.`}
        <ul parentName="li">
          <li parentName="ul">{`Social off`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      